import cn from 'classnames'
import Link from 'next/link'
import Image from 'next/image'
import ImageBurger from '@/app/src/widgets/Header/img/burger-small.png'
import { Button } from '@/app/src/shared/ui/Button'

import './FoodtechSlot.scss'

export type IProps = {
  className?: string
}

const FoodtechSlot = ({ className }: IProps) => {
  return (
    <div
      className={cn('foodtech-slot', {
        [`${className}`]: !!className,
      })}
    >
      <div className="foodtech-slot__foodtech">
        <div className="foodtech-slot__foodtech-left">
          <Link href="/foodtech" className="foodtech-slot__foodtech-image">
            <Image quality={100} width="96" height="96" src={ImageBurger} alt="foodtech-image" />
          </Link>

          <div className="foodtech-slot__foodtech-text-box">
            <div className="foodtech-slot__foodtech-title">Food Tech</div>

            <div className="foodtech-slot__foodtech-link">
              <Button size="m" href="/foodtech" hasIconRight variant="main-ghost-hidden-padding">
                Explore
              </Button>
            </div>
          </div>
        </div>

        <div className="foodtech-slot__foodtech-right">
          <div className="foodtech-slot__foodtech-text">
            We implement projects for food tech startups and experienced players of the restaurant
            market.
          </div>
        </div>
      </div>
    </div>
  )
}

export default FoodtechSlot
