import { isClient } from '@/app/src/shared/lib/isServerClient'
import { useToggle } from 'ahooks'
import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'
import { usePreventScroll } from 'react-aria'

const getScrollingState = () => isClient && window.scrollY > 1

export const useHeader = () => {
  const pathname = usePathname()
  const [isOpenedSubmenu, setStateSubmenuOpened] = useState(false)
  const [isOpenedMobileMenu, { toggle: toggleMobileMenu }] = useToggle(false)
  const [isPinned, setStatePinned] = useState(false)
  const [isScrolled, setScrolled] = useState(false)

  usePreventScroll({ isDisabled: !isOpenedMobileMenu })

  useEffect(() => {
    if (isOpenedMobileMenu) {
      toggleMobileMenu()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {
    setScrolled(getScrollingState())

    const fn = () => {
      setScrolled(getScrollingState())
    }

    window.addEventListener('scroll', fn)

    return () => {
      window.removeEventListener('scroll', fn)
    }
  }, [])

  const handlePin = () => {
    setStatePinned(true)
  }

  const handleUnPin = () => {
    setStatePinned(false)
  }

  return {
    isScrolled,
    isOpenedSubmenu,
    setStateSubmenuOpened,
    isPinned,
    isOpenedMobileMenu,
    handlePin,
    handleUnPin,
    toggleMobileMenu,
  }
}
