'use client'

import cn from 'classnames'
import { Container } from '@/app/src/shared/ui/Container'
import Link from 'next/link'
import Logo from '../../img/logo.svg'
import { Navigation } from '@/app/src/widgets/Header/ui/Navigation'
import { Button } from '@/app/src/shared/ui/Button'
import IconToggler from '../../img/toggler.svg'
import IconTogglerOpened from '../../img/toggler-opened.svg'

export type IProps = {
  className?: string
  headroom?: boolean
  isOpenedMobileMenu?: boolean
  isVariantWhite?: boolean
  onChangeSubmenu?: (val: boolean) => void
  onChangeMobileMenu?: () => void
}

const Inner = ({
  className,
  isVariantWhite = false,
  headroom = false,
  isOpenedMobileMenu,
  onChangeSubmenu,
  onChangeMobileMenu,
}: IProps) => {
  return (
    <div
      className={cn('header__container', {
        [`${className}`]: !!className,
        'header__container--headroom': headroom,
        'header__container--variant-white': isVariantWhite,
      })}
    >
      <Container size="infinity">
        <div className={cn('header__inner')}>
          <div className={cn('header__left')}>
            <Link href="/" className={cn('header__logo')}>
              <Logo className="header__logo-img" width="104" height="54" />
            </Link>
          </div>

          <div className={cn('header__right')}>
            <Navigation isVariantWhite={isVariantWhite} onChangeSubmenu={onChangeSubmenu} />

            <div className={cn('header__button')}>
              <Button href="/project-evaluation#main">Estimate Project</Button>
            </div>

            {onChangeMobileMenu && (
              <button
                onClick={onChangeMobileMenu}
                className={cn('header__button-toggler', {
                  'header__button-toggler--open': isOpenedMobileMenu,
                })}
              >
                <IconToggler
                  className="header__button-icon-front"
                  width="44"
                  height="44"
                  viewBox="0 0 52 52"
                />
                <IconTogglerOpened
                  className="header__button-icon-back"
                  width="44"
                  height="44"
                  viewBox="0 0 52 52"
                />
              </button>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Inner
