'use client'

import cn from 'classnames'
import Link from 'next/link'
import { Icon } from '@/app/src/shared/ui/Icon'
import { useRef, useState } from 'react'
import isTouchEnabled from '@/app/src/shared/lib/isTouch'
import { Container } from '@/app/src/shared/ui/Container'
import { FoodtechSlot } from '@/app/src/widgets/Header/ui/FoodtechSlot'
import { usePathname } from 'next/navigation'

import './Navigation.scss'
import { nav } from '@/app/src/shared/config/navigation'
import { INextLink } from '@/app/src/shared/model'
import { AnyIconName } from '@/app/src/shared/ui/Icon/lib'

type Props = {
  onChangeSubmenu?: (val: boolean) => void
  isVariantWhite?: boolean
}

const Navigation = ({ onChangeSubmenu, isVariantWhite = false }: Props) => {
  const [enterSubmenuId, setEnterSubmenuId] = useState<null | number>(null)
  const [leaveSubmenuIdStart, setLeaveSubmenuIdStart] = useState<null | number>(null)
  const [leaveSubmenuIdFinish, setLeaveSubmenuIdFinish] = useState<null | number>(null)

  return (
    <nav
      className={cn('header-navigation', {
        'header-navigation--variant-white': isVariantWhite,
      })}
    >
      <ol className={cn('header-navigation__list')}>
        {nav.map((item) => (
          <Item
            key={item.id}
            itemMenu={item}
            onChangeSubmenu={onChangeSubmenu}
            prevSubmenuIdStart={leaveSubmenuIdStart}
            prevSubmenuIdFinish={leaveSubmenuIdFinish}
            currentSubmenuId={enterSubmenuId}
            onChangeSubmenuEnterId={setEnterSubmenuId}
            onChangeSubmenuLeaveIdStart={setLeaveSubmenuIdStart}
            onChangeSubmenuLeaveIdFinish={setLeaveSubmenuIdFinish}
          />
        ))}
      </ol>
    </nav>
  )
}

type PropsItem = {
  onChangeSubmenuEnterId: (id: number | null) => void
  onChangeSubmenuLeaveIdFinish: (id: number | null) => void
  onChangeSubmenuLeaveIdStart: (id: number | null) => void
  onChangeSubmenu?: (val: boolean) => void

  currentSubmenuId: null | number

  prevSubmenuIdStart: null | number
  prevSubmenuIdFinish: null | number

  itemMenu: (typeof nav)[number]
}

const Item = ({
  itemMenu,
  onChangeSubmenu,
  onChangeSubmenuEnterId,
  onChangeSubmenuLeaveIdStart,
  onChangeSubmenuLeaveIdFinish,
  currentSubmenuId,
  prevSubmenuIdStart,
  prevSubmenuIdFinish,
}: PropsItem) => {
  const pathname = usePathname()
  const timeoutId = useRef<ReturnType<typeof setTimeout>>()
  const hasSubmenu = itemMenu.subnav && !!itemMenu?.subnav.length
  const hasFoodtech = itemMenu.hasFoodtech
  const hasSlot = itemMenu.hasSlot
  const submenuItemActive = currentSubmenuId === itemMenu.id
  const submenuItemLeaved =
    prevSubmenuIdStart === itemMenu.id && prevSubmenuIdStart !== prevSubmenuIdFinish

  const handleSubmenuClick = (e: React.SyntheticEvent) => {
    if (hasSubmenu && isTouchEnabled()) {
      e.preventDefault()

      onChangeSubmenu?.(true)
      onChangeSubmenuEnterId(itemMenu.id)

      if (currentSubmenuId === itemMenu.id) {
        onChangeSubmenuEnterId(null)
        onChangeSubmenu?.(false)
      }
    }
  }

  const handleSubmenuHover = () => {
    if (isTouchEnabled()) return
    if (!hasSubmenu) return

    onChangeSubmenuEnterId(null)

    if (timeoutId.current) clearTimeout(timeoutId.current)

    onChangeSubmenu?.(true)
    onChangeSubmenuEnterId(itemMenu.id)
  }

  const handleSubmenuLeave = (e: React.SyntheticEvent) => {
    if (isTouchEnabled()) return
    if (!hasSubmenu) return

    onChangeSubmenuEnterId(null)
    onChangeSubmenuLeaveIdStart(null)
    onChangeSubmenuLeaveIdFinish(null)
    onChangeSubmenu?.(false)

    // @ts-ignore
    const hasSubnavParent = e?.relatedTarget?.classList?.contains('header-navigation__subnav-menu')

    if (!hasSubnavParent) {
      onChangeSubmenuLeaveIdStart(itemMenu.id)

      timeoutId.current = setTimeout(() => {
        onChangeSubmenuLeaveIdFinish(itemMenu.id)
      }, 300)
    }
  }

  return (
    <li
      className={cn('header-navigation__item', {
        'header-navigation__item--submenu-opened': submenuItemActive || submenuItemLeaved,
        'header-navigation__item--submenu-active': submenuItemActive,
      })}
    >
      <Link
        onMouseLeave={handleSubmenuLeave}
        onMouseEnter={handleSubmenuHover}
        onClick={handleSubmenuClick}
        href={`${itemMenu?.href}#main` as INextLink}
        className={cn('header-navigation__link', {
          'header-navigation__link--active': pathname?.startsWith(itemMenu.href as string),
        })}
      >
        <span>{itemMenu?.title}</span>

        {hasSubmenu && (
          <div className={cn('header-navigation__icon-submenu')}>
            <Icon width="20" height="20" name="sprite/chevron-down" />
          </div>
        )}
      </Link>

      {hasSubmenu && (
        <div
          className={cn('header-navigation__subnav', {
            'header-navigation__subnav--has-slot': hasSlot || hasFoodtech,
          })}
          onMouseLeave={handleSubmenuLeave}
          onMouseEnter={handleSubmenuHover}
        >
          <Container size="s">
            <div className={'header-navigation__subnav-menu-wrapper'}>
              <div className={cn({ 'header-navigation__subnav-menu': hasFoodtech })}>
                <ol className={'header-navigation__subnav-list'}>
                  {itemMenu.subnav.map(
                    ({ title, href, id, iconName, isComingSoon, description }) => {
                      return (
                        <li
                          key={id}
                          className={cn('header-navigation__subnav-item', {
                            [`header-navigation__subnav-item--not-industries`]: !hasFoodtech,
                          })}
                        >
                          <Link
                            href={`${href}` as INextLink}
                            className={cn('header-navigation__subnav-link', {
                              'header-navigation__subnav-link--active': pathname?.startsWith(
                                href as string,
                              ),
                              'header-navigation__subnav-link--disabled': isComingSoon,
                              'header-navigation__subnav-link--with-description': description,
                              'header-navigation__subnav-link--icon-without-box': hasSlot,
                            })}
                          >
                            {iconName && (
                              <div
                                className={cn('header-navigation__subnav-icon', {
                                  'header-navigation__subnav-icon--has-foodtech':
                                    hasFoodtech || itemMenu.id === 1,
                                })}
                              >
                                <Icon name={iconName as AnyIconName} width={32} height={33} />
                              </div>
                            )}
                            <div className="header-navigation__subnav-info-wrap">
                              <div>
                                <div>{title}</div>
                                {description && (
                                  <div className="header-navigation__subnav-description">
                                    {description}
                                  </div>
                                )}
                              </div>
                              {isComingSoon && <span>coming soon</span>}
                            </div>
                            {hasSlot && (
                              <Icon
                                name={'sprite/chevron-right'}
                                width={20}
                                height={20}
                                className={'header-navigation__subnav-arrow-icon'}
                              />
                            )}
                          </Link>
                        </li>
                      )
                    },
                  )}
                </ol>
              </div>

              {hasFoodtech && (
                <div className="header-navigation__subnav-slot">
                  <FoodtechSlot />
                </div>
              )}
            </div>
          </Container>
        </div>
      )}
    </li>
  )
}

export default Navigation
